<template>
  <div v-if="dealer && training" class="world academy training">

    <div class="wrapper-training">
      <div class="container">
        <div class="grid">
          <div class="overview">
            <router-link :to="{name: 'ww.trainings', params: {id: trainingId }}" class="text-wilmsLightBlue block">&larr; {{ $t('Terug naar opleidingen') }}</router-link>
            <h1>{{ getTranslation(training).title }}</h1>
            <div class="intro" v-html="getTranslation(training).body"></div>

            <div class="months-col">
              <h2>{{ $t('Inschrijven') }}</h2>
              <div class="help">{{ $t('Klik op een van onze beschikbare data en schrijf je vervolgens in voor deze opleiding') }}</div>

              <div class="year-switcher">
                <button :disabled="year <= datetime.now().year" class="prev" @click="prevYear">
                  <Icon color="#0F1D61" height="30" icon="akar-icons:chevron-left"></Icon>
                </button>
                <button class="next" @click="nextYear">
                  <Icon color="#0F1D61" height="30" icon="akar-icons:chevron-right"></Icon>
                </button>
                <div class="year">{{ year }}</div>
              </div>

              <div v-if="eventsByMonth[year]" class="months">
                <div v-for="(month, monthNumber) in eventsByMonth[year]" :key="monthNumber" class="month">
                  <h3>{{ datetime.now().set({ month: monthNumber }).setLocale(activeLocale).toFormat('MMMM') }}</h3>
                  <div class="events">
                    <div v-for="event in month" :key="event.id" :class="{ 'active': selectedEvent && selectedEvent.id === event.id, 'disabled': (event.seats - event.seats_taken) === 0 }" class="event" @click="selectTrainingEvent(event)">
                      <div class="day">
                        {{ datetime.fromISO(event.event_start).setLocale(activeLocale).toFormat('dd/MM') }}
                      </div>
                      <div class="time">
                        {{ datetime.fromISO(event.event_start).setLocale(activeLocale).toFormat('HH:mm') }} - {{ datetime.fromISO(event.event_end).setLocale(activeLocale).toFormat('HH:mm') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="no-data">{{ $t('Er is geen data beschikbaar voor deze training.') }}</div>
            </div>
          </div>

          <div class="details-col">
            <div class="details">
              <div class="detail">
                <Icon color="#79B0EA" icon="ic:baseline-location-on" width="20"/>
                <h3 v-html="getTranslation(training).location"></h3>
              </div>
              <div class="detail">
                <Icon color="#79B0EA" icon="ant-design:info-circle-outlined" width="20"/>
                <h3 v-html="getTranslation(training).info"></h3>
              </div>
              <div v-if="!(getTranslation(training).programme === null || getTranslation(training).programme === '' || getTranslation(training).programme === '<p> </p>')" class="detail programme">
                <Icon color="#79B0EA" icon="bx:bx-time-five" width="20"/>
                <h3>{{ $t('Voorwaarden') }}</h3>
                <div class="programme" v-html="getTranslation(training).programme"></div>
              </div>
            </div>

            <div v-if="images[training.file_id]" class="image">
              <img :alt="getTranslation(training).title" :src="images[training.file_id].url">
            </div>

            <div class="selected-event-col">
              <div v-if="selectedEvent" class="selected-event">
                <h3>{{ datetime.fromISO(selectedEvent.event_start).setLocale(activeLocale).toFormat('EEEE dd MMMM yyyy') }}</h3>
                <div class="time">
                  {{ datetime.fromISO(selectedEvent.event_start).setLocale(activeLocale).toFormat('HH:mm') }} - {{ datetime.fromISO(selectedEvent.event_end).setLocale(activeLocale).toFormat('HH:mm') }}
                </div>
                <div class="seats-available">
                  <Icon color="#79B0EA" height="20" icon="clarity:group-solid"/>
                  <span>{{ $t('academy.training.seats_open', { 0: selectedEvent.seats - selectedEvent.seats_taken }) }}</span>
                </div>
                <div v-if="selectedEvent.seats - selectedEvent.seats_taken > 0" class="btn" @click="openBookingModal">{{ $t('Schrijf je team in') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal :scrollable="true" :width="885" class="modal booking-modal" height="auto" name="bookingModal" @closed="closeBookingModal">
      <button class="btn-close" @click="closeBookingModal"><img alt="Close" src="@/assets/img/close.svg"></button>

      <div v-if="!bookingSuccess" class="content">
        <h2>{{ $t('Inschrijven voor opleiding') }}</h2>
        <h3>{{ $t('Opleiding:') }} {{ getTranslation(training).title }}</h3>
        <p v-if="selectedEvent">
          {{ datetime.fromISO(selectedEvent.event_start).setLocale(activeLocale).toFormat('EEEE dd MMMM yyyy') }}
          {{ $t('van') }}
          {{ datetime.fromISO(selectedEvent.event_start).setLocale(activeLocale).toFormat('HH:mm') }} {{ $t('tot') }} {{ datetime.fromISO(selectedEvent.event_end).setLocale(activeLocale).toFormat('HH:mm') }}
        </p>

        <formulate-form ref="bookingForm" v-model="booking" name="bookingForm" @submit="saveBooking">
          <h3>{{ $t('Gegevens bedrijf') }}</h3>
          <formulate-input :label="$t('Bedrijf')" :wrapper-class="['lg']" name="company_name" type="text" validation="required"></formulate-input>

          <formulate-input :label="$t('Straat en nr')" name="company_address1" type="text" validation="required"></formulate-input>
          <div class="group">
            <formulate-input :label="$t('Postcode')" name="company_postal_code" type="text" validation="required"></formulate-input>
            <formulate-input :label="$t('Gemeente')" name="company_locality" type="text" validation="required"></formulate-input>
          </div>
          <div class="group">
            <formulate-input :label="$t('Land')" :options="countries" name="company_country_code" type="select" validation="required"></formulate-input>
          </div>

          <h3>{{ $t('Gegevens contactpersoon') }}</h3>
          <div class="group">
            <formulate-input :label="$t('Contactpersoon voornaam')" name="contact_first_name" type="text" validation="required"></formulate-input>
            <formulate-input :label="$t('Contactpersoon achternaam')" name="contact_last_name" type="text" validation="required"></formulate-input>
          </div>
          <div class="group">
            <formulate-input :label="$t('E-mailadres')" name="contact_email" type="email" validation="required|email"></formulate-input>
            <formulate-input :label="$t('Telefoonnummer')" name="contact_telephone" type="tel" validation="required"></formulate-input>
          </div>

          <h3>{{ $t('Deelnemers opleiding (vergeet jezelf niet)') }}</h3>
          <formulate-input :add-label="$t('+ Voeg deelnemer toe')" :repeatable="true" name="participants" remove-label="X" type="group">
            <formulate-input :label="$t('Voornaam')" name="first_name" type="text" validation="required"></formulate-input>
            <formulate-input :label="$t('Achternaam')" name="last_name" type="text" validation="required"></formulate-input>
            <formulate-input :label="$t('Functie')" name="company_position" type="text" validation="required"></formulate-input>
          </formulate-input>

          <formulate-input :label="$t('Heb je speciale vragen of opmerkingen? Allergieën voor de lunch? Laat het ons hieronder weten.')" name="extra_info" type="textarea"></formulate-input>

          <formulate-input :label="isLoading ? $t('Even geduld...') : $t('Inschrijven')" input-class="btn" type="submit"></formulate-input>
        </formulate-form>
      </div>
      <div v-if="bookingSuccess" class="content success">
        <div class="icon">
          <Icon height="50" icon="bi:calendar-check"></Icon>
        </div>
        <h2>{{ $t('Bedankt voor uw inschrijving!') }}</h2>
        <p>{{ $t('U heeft een mail ontvangen met een bevestiging.') }}</p>
      </div>
    </modal>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { Icon } from '@iconify/vue2'
import { DateTime } from 'luxon'
import Vue from 'vue'
import { notification } from '@/notifications'

export default {
  components: {
    Icon
  },
  data () {
    return {
      isLoading: false,
      training: null,
      trainingId: this.$route.params.id,
      selectedEvent: null,
      bookingSuccess: false,
      datetime: DateTime,
      year: DateTime.now().year,
      booking: {},
      countries: {
        be: this.$t('België'),
        nl: this.$t('Nederland'),
        fr: this.$t('Frankrijk'),
        de: this.$t('Duitsland'),
        lu: this.$t('Luxemburg')
      }
    }
  },
  async mounted () {
    await this.setDealer()
    await ApiService.fetchTraining(this.trainingId).then(async res => {
      this.training = res.data
      await this.$store.dispatch('fetchImages', { objects: [this.training], value: 'file_id' })
    })
    this.setDefaultBooking()
  },
  methods: {
    selectTrainingEvent (trainingEvent) {
      this.selectedEvent = this.selectedEvent === trainingEvent ? null : trainingEvent
      const e = event

      if (this.selectedEvent) {
        Vue.nextTick(function () {
          const containerTop = document.querySelector('.selected-event-col').offsetTop
          const eventsTop = e.target.closest('.events').offsetTop
          document.querySelector('.selected-event').style.top = eventsTop - containerTop + 'px'
        })
      }
    },
    prevYear () {
      this.year--
    },
    nextYear () {
      this.year++
    },
    setDefaultBooking () {
      this.booking = {
        company_name: this.dealer.name,
        company_address1: this.dealer.address.address1,
        company_postal_code: this.dealer.address.postal_code,
        company_locality: this.dealer.address.locality,
        company_country_code: this.dealer.address.country_code,
        contact_first_name: this.currentUser.profile.first_name,
        contact_last_name: this.currentUser.profile.last_name,
        contact_email: this.currentUser.email,
        contact_telephone: this.dealer.phone,
        company_vat: '',
        participants: [
          {
            first_name: this.currentUser.profile.first_name,
            last_name: this.currentUser.profile.last_name
          }
        ]
      }
    },
    openBookingModal () {
      this.$modal.show('bookingModal')
      this.setDefaultBooking()
    },
    closeBookingModal () {
      this.$modal.toggle('bookingModal')

      if (this.bookingSuccess) {
        this.$router.push({ name: 'ww.trainings' })
      }
    },
    async saveBooking (formData) {
      this.isLoading = true
      formData.dealer_id = this.dealer.id
      formData.event_id = this.selectedEvent.id
      await ApiService.createTrainingEventBooking(this.trainingId, this.selectedEvent.id, formData)
        .then(res => {
          if (res.status === 200) {
            this.bookingSuccess = true
          } else if (res.response.status === 409) {
            notification(this.$tc(res.response.data.detail.message, res.response.data.detail.seats_over, { 0: res.response.data.detail.seats_over }), 'error')
          }
        })
      this.isLoading = false
    }
  },
  computed: {
    images () {
      return this.$store.getters.getImages
    },
    eventsByMonth () {
      const events = {}
      if (this.training && this.training.events.length > 0) {
        this.training.events.forEach(event => {
          if (event.language === this.activeLocale && event.published) {
            const date = DateTime.fromISO(event.event_start)

            if (date.startOf('day') > DateTime.now().startOf('day')) {
              if (!events[date.year]) {
                events[date.year] = {}
              }

              if (DateTime.fromISO(event.event_start).diffNow('days').days < 2) {
                event.seats = 0
                event.seats_taken = 0
              }

              if (!events[date.year][date.month]) {
                events[date.year][date.month] = []
              }

              events[date.year][date.month].push(event)
              events[date.year][date.month] = events[date.year][date.month].sort((a, b) => DateTime.fromISO(a.event_start).toJSDate().getTime() > DateTime.fromISO(b.event_start).toJSDate().getTime() ? 1 : -1)
            }
          }
        })
      }
      return events
    }
  }
}
</script>
